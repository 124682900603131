.task-form-card {
    border-radius: 0.8rem;
    background: transparent !important;
    box-shadow: none;
}

.task-form-group {
    margin-bottom: 1.5rem;
}

.task-form-label {
    font-weight: 600;
    color: #525f7f;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
}

.task-input-group {
    transition: box-shadow 0.15s ease;
    border-radius: 0.375rem;
    background: transparent;
}

.task-input-group:focus-within {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.task-input {
    border: 1px solid #e9ecef;
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    transition: all 0.15s ease-in-out;
    background: white;
}

.task-input:focus {
    border-color: rgba(50, 151, 211, 0.25);
    outline: 0;
}

.task-datetime {
    width: 100%;
}

.task-datetime input {
    background-color: white !important;
    border: 1px solid #e9ecef;
    padding: 0.625rem 0.75rem;
    height: calc(2.75rem + 2px);
    width: 100%;
    border-radius: 0.375rem;
}

.task-datetime input:focus {
    border-color: rgba(50, 151, 211, 0.25);
    outline: 0;
}

.task-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.task-button {
    padding: 0.625rem 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    transition: all 0.15s ease;
    min-width: 120px;
}

.task-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

textarea.task-input {
    min-height: 100px;
    resize: vertical;
}

.task-input:disabled {
    background-color: #f8f9fa !important;
    cursor: not-allowed;
}

.task-buttons input[type="checkbox"] {
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.task-buttons label {
    cursor: pointer;
    color: #525f7f;
    font-size: 0.875rem;
}

.task-form-footer {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #e9ecef;
}

.template-checkbox {
    margin-bottom: 1rem;
}

.template-checkbox .custom-control-label {
    font-size: 0.875rem;
    color: #525f7f;
}

.task-buttons-container {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #5e72e4;
    background-color: #5e72e4;
}