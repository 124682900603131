.erroInputBase{

	color:red;
	margin-top: 5px;

}

.imgup{

	height:100px !important;
	margin-bottom: 20px;

}

.bg-info{

	background: linear-gradient(-45deg,#7b142e,#d12646) !important;

}

.bg-gradient-info{

	background: linear-gradient(-45deg,#7b142e,#d12646) !important;
	
}

.ishidden_unit_c{ opacity: 0.5; }

.ishidden_unit_c:hover{ opacity: 1;}

.paneUnitMove{

	background-color: #fff;
	border-radius: 10px;
	padding: 0px 15px !important;
}

.paneUnitMove .list-group-item{

    background-color: #fff0 !important;
    border: 1px solid #e9ecef00 !important;

}

.paneUnit2Move{

	background-color: #fff;
	border-radius: 0px;
	padding: 0px 15px !important;

}

.iconUnitDialog{

	max-height: 150px;
	
}

.paneLessonMove{ 

	border-radius: 15px;

}

.timeline-one-side:before{

    height: -webkit-fill-available;

}

.avatarIconCourse{

    background-color: #adb5bd00 !important;
    border-radius: 0px 0px 0px 0px !important;

}

.avatarIconCourse img{ border-radius: 0px 0px 0px 0px !important; }

.lessonInputTagSomentTag{ background-color: #fff0; }
.lessonInputTagSomentTag .tagInputClass{ display: none; }
.lessonInputTagSomentTag .removeClass::after{ display: none; }
.lessonInputTagSomentTag .removeClass{ font-size: 18px; top: 49%; }


.lessonInputTagSomentInput .tag-wrapper{ display: none; }


.rc-time-picker-input{    padding: 0 !important; border: 1px solid #f8f9fe00 !important;     color: #8898aa !important; font-size: 0.875rem !important;}

.rc-time-picker-panel-inner{     

    border: 1px solid #cccccc00 !important;
    box-shadow: 0 1px 5px #fdfdfd !important;
    color: #8898aa !important;
	padding: 0px;
    margin-top: 13px;

}

.rc-time-picker-panel-input{ color: #8898aa !important; font-size: 0.875rem !important;}

.rc-time-picker-panel-input-wrap{ border-bottom: 1px solid #e9e9e9;  padding: 2px 0px 10px 11px;}

.navbar-brand{

    padding: 0px;

}

.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand .logo_principal{

	max-width: 100% !important;
    max-height: 9999px !important;
    height: 88px !important;

}

.navbar-brand{

	padding-left: 2px;

}

.paneUnit3Move .ql-editor{    height: 96px;}
.paneUnit3Move { background-color: #fff; }
.paneUnit3Move svg{pointer-events: none;}

.rna-wrapper  div{ z-index: 99999 !important; }

.header-shadow{

	left: 19px; bottom: -30px; background: url('https://i.imgur.com/fh4ldeD.png') no-repeat top; height: 30px; z-index: 299;

}

.viewLesson{

	background-color: #fff;

}
 
.question-answer > :first-child{ pointer-events: none; }

.listLessonsTop{

    overflow: hidden;
    width: 100%;
    display: -webkit-box;
	box-shadow: 0px 11px 8px -11px #000;
    padding-left: 20px;
    border-radius: 0px 0px 15px 15px;
    width: max-content;

}

.dashboard_class_userList_user:first-child{
 
}

.buttonOpenDashBoard{

    position: absolute;
    left: calc(100% - 26px);
    top: 7px;
 
}

.lessonTop{

	width: 300px;
    height: 28px;

}

.inputViwerName_classroom{

    border: none;

}

.dashboard_class_user{
    border-top: 1px solid #ffffff00;
    position: relative;
}

.dashboard_class_user{

	border-top: 1px solid #e9ecef;
	padding-bottom: 3px;
	padding-top: 0px;
	padding-left: 10px;

}

.dashboard_class_user .avatar{

	width: 30px;
	height: 30px;
    margin-right: 3px !important;

}

.dashboard_class_userList_user{

	height: 55px;
	border-top: 1px solid #e9ecef;
	padding-bottom: 10px;
   
    display: -webkit-box;
    overflow:hidden;
    padding-left: 20px;
    position: relative;
    width: max-content;

}

.listTime_dashboard_class_room{

		width: 300px;
		position: relative;
}

.hidden_progress{ display: none; }

.outras_tentativas{

	color: #000;
    box-shadow: 0px 2px 2px -3px #000;
    padding-left: 0px;
    margin-bottom: 0px;
    font-size: 13px;

}

.outras_tentativas .date{


}

.outras_tentativas .timer{
    margin-right: 10px;
    float: right;
    color:#727c97;
}

.tot_register{

    font-size: 28px;
    color: #0000002e;
	float:right;
    margin-right: 10px;

    top: 5px;
    position: absolute;
    left: calc(100% - 55px);
    font-family: monospace;

}

.resto_progresso{ margin-top: 10px; }

.rrasta{ pointer-events: none; }

.p1 {
    position: absolute;
}

#qr {
    position: absolute;
    top: 80px;
    left: 385px;
    border-radius: 10px;
    box-shadow: 0px 2px 7px -1px #000;
    background-color: #fff;
    padding: 8px;
}

.padrao_cartao {
    position: relative;
    width: 600px;  
    height: 340px;
    
}

.p1 i {
    background-image: linear-gradient(#931736, #d12646);
    color: #fff;
    font-size: 18px;
    padding: 9px;
    border-radius: 27px;
    box-shadow: 0px 1px 3px -1px #000;
}

.p1 font {

    margin-left: 16px;
    font-weight: 700;
    position: relative;
    top: -3px;
    color: #000;

}

.row_carduser{ 
 
    z-index: -999;
    opacity: 0;
    margin-top: 10px;
    width: 1260px;
    height: 340px;
    position: fixed;
    z-index: 0;

}

.congratulations{

  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 40%;
  left: 50%;
  margin: -100px 0 0 -150px; 

}

.textViwerHtml{

    margin: 20px 20px 0px 20px;

}

.legenda_minimal_description{

    display: flex;
    margin-bottom: 5px;
    padding-left: 5px;

}

.legendaRelatorioTurma{

    margin-bottom:5px;
    height: 150px;
    overflow-y: auto;

}

.dashboard_class_user_5{ padding-top: 13px; }

.dashboard_class_user_2{

    padding-top: 12px;

}

.legendaRelatorioTurma font{ 

    margin-left:10px; 
    margin-top:1px; 
    font-weight: 600;  

}

.legendaRelatorioTurma .lengd{ margin-right:5px; }

.lengd{ cursor: help; }


.listTime_dashboard_class_room_min{

   
    border-right: 0.6px solid #e6e6e6;
}

.lessonTop_minshadown{

    box-shadow: 0px 3px 0px -2.5px #d9d9d9;
    -webkit-backface-visibility:hidden;

}

.linha_icone{

    height: 0.6px;
    width: 1000px;
    top: 132px;
    position: absolute;
    background-color: #e8e8e8;
    display: none;

}

.nameUnitMinDashBoardClassroom{

    position: absolute;
    top: -4px;
    left: 2px;

}

.nameUnitMinDashBoardClassroom_icon{

    left: -4px;
    position: absolute;
    display: none;

}

.icon_progressunit_1{

    height:24px;

}
.calendarStudend{

    display: flex;
    margin-bottom:10px;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-radius: 5px;
    overflow:hidden;

}
.calendarStudendLesson{
 
    width: 200px;

}

.divCenterCalendarStudent{

    width:200px;

}

.imgDivCenterCalendar{

    width: 25px;

}

.divCenterCalendarStudent.lesson{

    font-weight: bolder;
    font-size: 14px;

}

.divCenterCalendarStudent.lock_desc_lesson{

    margin-top:10px;

}

.calendarStudendLesson{
    margin-top:10px;
    margin-bottom:5px;
}

.imgCenterCalendarA1{
    max-height: 135px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.divIconLeftCalendarDash{
    border-radius: 5px 0px 0px 5px;
     padding-top: 38px;
    padding-left: 7px;
    background-color: #eff1fc;
    padding-right: 7px;
    box-shadow: 2px 0px 9px -7px #000;
    cursor: pointer;
    position: absolute;
    height: calc(100% - 10px);
    z-index: 1;
}

.divIconRightCalendarDash{
    border-radius: 0px 5px 5px 0px;
    padding-top: 38px;
    padding-left: 7px;
    background-color: #eff1fc;
    padding-right: 7px;
    box-shadow: -2px 0px 9px -7px #000;
    cursor: pointer;   
    position: absolute;
    height: calc(100% - 10px);
    left: calc(100% - 20px);
    z-index: 1;
}


.tabCimaImportXLSX div{

    font-size: 16px;
    width: 120px;
    margin-right:20px;

}

.tabCimaImportXLSX input{

    width: 110px;

}

.div_tab_importxlsx td{
    
    background-color: #fff;

}

.div_tab_importxlsx input{ border:none; }
.div_tab_importxlsx select{ border:none; }

.table_importXLXS td{ padding: 4px; }

.table_importXLXS td{

    padding-left: 5px !important;
    padding-right: 0px !important;

 }

.table_importXLXS tr:hover td{ background-color: #f7f7f7; }

.div_tab_importxlsx th{

    padding-left: 5px !important;
    padding-right: 0px !important;

}
 

.navbar-vertical.navbar-expand-xs{

    max-width: 55px;

}

@media (min-width: 1200px){

    .sidenav:hover {
        max-width: 250px !important;
    }

}

.g-sidenav-hidden:not(.g-sidenav-show) .sidenav .nav-link-text{

    display: block !important;

}

.sidenav:hover .navbar-brand{

    display: block;

}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link{

    padding: 0.675rem 1.35rem;
    
}

.navbar-vertical.navbar-expand-xs.fixed-left + .main-content{

   

}


.fastShow{

    animation-duration: 0s !important;

}

.dashboard_class_user:hover{ background-color: #f1f1f1; }

.dashboard_class_user:hover .inputViwerName_classroom{ background-color: #f1f1f1; }
.inputViwerName_classroom{ background-color: #f7fafc; }
.box_left_detailed{ padding-right: 0px; }


.dialogfastuserchart .apexcharts-legend{ top: 37px !important; }


.select_charts_fast .dropdown{

    width: 230px;
    text-align: justify;
    z-index:999;
    
}

.select_charts_fast{ 
    
    text-align: center;  

}

.select_charts_fast .dropdown-heading{

    border: 1px solid rgb(255, 255, 255) !important;
    box-shadow: 0px 1px 4px -4px #000 !important;
}

.select_charts_fast .dropdown-content{

    border-color: #fff !important;
    box-shadow: 0px 1px 4px -4px #000 !important;
}


.chartCol12{ margin-bottom: 30px; }





.iframeEngine{

    position: absolute;
    top:0px;
    z-index: 999;
    
}


.txtLessonTop{

    white-space: nowrap;
    position: relative;
    width: 50px;
    transform: rotate(-52deg);
    height: 28px;
    top: 20px;

}

.txtLessonTop:last-child{ margin-right: 90px;}


.login_mais{ 
    
    height: 14px !important;
    margin-left: 5px; margin-right: 5px; 

}

.logologin_super,.logologin_cs { height:65px; }

.sidenav-header .login_mais{ 
    
    height: 12px !important; 

}

.sidenav-header .logologin_super{

    height: 30px !important;

} 

.sidenav-header .logologin_cs{

    height: 30px !important;

}

.block_unit{ 

    position: absolute;
    
    width: 100%;
    height: 100%;
    z-index: 9;
    padding-bottom: 15px;

}

.block_unit_aa{

    background-color: #142b3a;

    box-shadow: 0px 1px 5px -3px #000;
    border-radius: 5px;

    position: relative;
    
    width: 100%;
    height: 100%;
}

.block_unit_aa font{

    color:#fff;
    font-weight: 600;
    font-size: 15px;
    position: relative;
    top: 6px;

}



.input_select_custom_importa_usuario > div > div{ position: inherit !important; }

.input_select_custom_importa_usuario .react-dropdown-select-dropdown{

    left: 37%;
    width: 300px;
    overflow-x: hidden;
    border-radius: 10px;
    border: 0px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
     
}


.input_select_custom_importa_usuario .react-dropdown-select{  background-color: #fff;  border-color: #0074d900; box-shadow: 0 0 0 3px rgba(0, 116, 217, 0); }
.input_select_custom_importa_usuario .react-dropdown-select:hover{    border-color: #0074d900; box-shadow: 0 0 0 3px rgba(0, 116, 217, 0); }
.input_select_custom_importa_usuario .react-dropdown-select:focus{    border-color: #0074d900; box-shadow: 0 0 0 3px rgba(0, 116, 217, 0); }

.input_select_custom_importa_usuario .custom-checkbox{

    margin:1px 10px;

}

.input_select_custom_importa_usuario .custom-checkbox:hover{

    background-color: #f3f3f3;
    border-radius: 5px;
    
}

.input_select_custom_importa_usuario .react-dropdown-select-dropdown{ width: auto !important; overflow-x: auto !important; }
 

.vertical-timeline:before {
    background-color: #ddd;
}


.select_professor_bb .select-search-box__search,
.select_course .select-search-box__search {
    box-shadow: 0px 0px;
    margin-top: 3px;
    width: 100%;
    padding: 0 9px;
    color: #8898aa;
}

.select_professor_bb .select-search-box,
.select_course .select-search-box {
    width: 100% !important;
}



.div_custom_classroom{

    display: flex;
    justify-content: center;

}

.div_custom_classroom .frsc{

    font-size: 15px;
    margin-left: 5px;

}
.modal-comment-remove {
    z-index: 1052 !important;
}
/* modal das tarefas */

  .some-custom-class {
    z-index: 1051 !important;
    }

    .some-custom-overlay-class {
        z-index: 1051 !important;
    }
  
    .tasks-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
        gap: 28px;
        padding: 28px 0;
        margin: 0 auto;
        max-width: 1400px;
    }
    
    .task-card {
        background: #ffffff;
        border: 1px solid #e9ecef;
        border-radius: 16px;
        padding: 24px;
        transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
        position: relative;
        overflow: hidden;
    }
    
    .task-card::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(90deg, #3b4197, #1916dd);
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    
    .task-card:hover {
        transform: translateY(-6px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
    }
    
    .task-card:hover::before {
        opacity: 1;
    }
    
    .task-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 24px;
        padding-bottom: 16px;
        border-bottom: 1px solid #f0f0f0;
    }
    
    .task-title {
        margin: 0;
        font-size: 1.25rem;
        font-weight: 700;
        color: #1a202c;
        line-height: 1.4;
        letter-spacing: -0.01em;
    }
    
    .task-actions {
        display: flex;
        gap: 16px;
        opacity: 0.7;
        transition: opacity 0.2s ease;
    }
    
    .task-card:hover .task-actions {
        opacity: 1;
    }
    
    .task-details {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
    
    .task-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #f5f5f5;
        transition: all 0.2s ease;
    }
    
    .task-info:hover {
        background-color: #f8fafc;
        padding: 10px 8px;
        border-radius: 8px;
    }
    
    .info-label {
        color: #64748b;
        font-weight: 600;
        font-size: 0.95rem;
    }
    
    .info-value {
        color: #334155;
        font-weight: 600;
        font-size: 0.95rem;
    }
    
    .status-badge {
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 0.875rem;
        font-weight: 600;
        text-transform: capitalize;
        transition: all 0.3s ease;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
    }
    
    .status-pending {
        background-color: #fff7ed;
        color: #9a3412;
        border: 1px solid #fed7aa;
    }
    
    .status-pending:hover {
        background-color: #ffedd5;
    }
    
    .status-completed {
        background-color: #f0fdf4;
        color: #166534;
        border: 1px solid #bbf7d0;
    }
    
    .status-completed:hover {
        background-color: #dcfce7;
    }
    
    .status-rejected {
        background-color: #fef2f2;
        color: #b91c1c;
        border: 1px solid #fecaca;
    }
    
    .status-rejected:hover {
        background-color: #fee2e2;
    }
    
    .edit-sliding-pane {
        z-index: 1051 !important;
        box-shadow: -4px 0 16px rgba(0, 0, 0, 0.1) !important;
        transition: transform 0.3s ease-in-out !important;
    }
    
    .edit-sliding-pane-overlay {
        z-index: 1051 !important;
        backdrop-filter: blur(2px);
        transition: opacity 0.3s ease !important;
    }
    
    .edit-form-container {
        background: linear-gradient(to bottom, #f8fafc, #ffffff);
        border-radius: 16px;
        padding: 24px;
        margin-top: 20px;
    }
    
    .file-input-group {
        position: relative;
        margin-bottom: 24px;
    }
    
    .file-input-group label {
        display: block;
        font-weight: 600;
        color: #334155;
        margin-bottom: 12px;
    }
    
    .file-input-wrapper {
        position: relative;
        width: 100%;
        height: 120px;
        border: 2px dashed #cbd5e1;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f8fafc;
        transition: all 0.3s ease;
        cursor: pointer;
    }
    
    .file-input-wrapper:hover {
        border-color: #94a3b8;
        background-color: #f1f5f9;
    }
    
    .file-input-wrapper input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
    
    .file-input-placeholder {
        text-align: center;
        color: #64748b;
    }
    
    .file-input-placeholder i {
        font-size: 24px;
        margin-bottom: 8px;
        color: #94a3b8;
    }
    
    .edit-form-buttons {
        display: flex;
        gap: 16px;
        justify-content: center;
        margin-top: 32px;
    }
    
    .edit-form-buttons .btn {
        min-width: 120px;
        padding: 10px 24px;
        font-weight: 500;
        border-radius: 8px;
        transition: all 0.2s ease;
    }
    
    .edit-form-buttons .btn-primary {
        background-color: #0ea5e9;
        border: none;
        box-shadow: 0 2px 4px rgba(14, 165, 233, 0.2);
    }
    
    .edit-form-buttons .btn-primary:hover {
        background-color: #0284c7;
        transform: translateY(-1px);
        box-shadow: 0 4px 6px rgba(14, 165, 233, 0.3);
    }
    
    .edit-form-buttons .btn-secondary {
        background-color: #f1f5f9;
        border: 1px solid #e2e8f0;
        color: #475569;
    }
    
    .edit-form-buttons .btn-secondary:hover {
        background-color: #e2e8f0;
        color: #334155;
    }
  
    /* form do modal de envio de tarefa */

    .input-group-alternative {
        transition: all 0.2s ease;
    }
    
    .input-group-alternative:focus-within {
        box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
        transform: translateY(-1px);
    }
    
    .form-control-label {
        font-size: 0.875rem;
        font-weight: 600;
        color: #525f7f;
        margin-bottom: 0.5rem;
    }
    
    .form-control-alternative {
        border: 0;
        box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .02);
    }
    
    .custom-file-label {
        border: 0;
        padding-left: 1rem;
    }
    
    .alert-dismissible {
        border: 0;
        border-radius: 0.375rem;
        box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .02);
    }


    /* professor vendo a tarefa */
    .deliveries-grid {
        display: grid;
        gap: 1rem;
    }

    .delivery-card {
        border: none;
        border-radius: 8px;
        transition: transform 0.2s;
    }

    .delivery-card:hover {
        transform: translateY(-2px);
    }

    .delivery-info {
        font-size: 0.9rem;
    }

    .info-item {
        display: flex;
        align-items: center;
    }

    .delivery-obs {
        background-color: #f8f9fa;
        padding: 1rem;
        border-radius: 6px;
        font-size: 0.9rem;
    }

    .badge {
        padding: 0.5em 1em;
        font-size: 0.8rem;
    }   

    .form-control-sm {
        height: calc(1.5em + 0.5rem + 2px);
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        border-radius: 0.2rem;
    }

    .tasks-column {
        max-width: 600px;
        margin: 0 auto;
    }

   
    .datetime-wrapper {
        position: relative;
    }
    .datetime-container {
        position: relative;
        width: 100%;
    }
    .rdtPicker {
        position: absolute !important;
        z-index: 9999 !important;
        min-width: 250px;
        display: block !important;
    }
    .rdt {
        position: static !important;
    }
    .rdt input {
        position: relative;
        z-index: 1;
    }
    .input-group .form-control {
        position: relative;
        z-index: 1;
    }
  

.highlight-comment {
    animation: highlightFade 3s ease-out;
}

@keyframes highlightFade {
    0% {
        background-color: rgba(56, 56, 56, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

.highlight-google-button {
    animation: pulse 1.5s infinite;
    box-shadow: 0 0 10px rgba(66, 133, 244, 0.7) !important;
    border: 2px solid #4285f4 !important;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.98);
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 10px 10px rgba(66, 133, 244, 0);
    }
    
    100% {
      transform: scale(0.98);
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0);
    }
  }
  
  .animated.pulse.infinite {
    animation: pulse 2s infinite;
  }

  