.filters-container {
    background-color: white;
    padding: 0.5rem 0;
}

.input-group {
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    transition: all 0.15s ease;
}

.input-group:focus-within {
    border-color: #5e72e4;
    box-shadow: 0 3px 9px rgba(50, 50, 93, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1);
}

.input-group-text {
    background-color: transparent;
    border: none;
    color: #8898aa;
    padding-left: 1rem;
}

.form-control-alternative {
    border: none !important;
    box-shadow: none !important;
    transition: all 0.15s ease;
}

.form-control-alternative:focus {
    background-color: white;
}

.form-control-alternative::placeholder {
    color: #8898aa;
}